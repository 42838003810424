.menu{
    width: 20px;
    height: 20px;
    border-top: 110px solid transparent;
    border-bottom: 110px solid transparent;
    border-right: 220px solid black;

}

.menuitem{
    text-align: center;
    left:110px;
    bottom:45px;
    position: relative;
}

.framerTextBox{
    box-shadow: 10px 10px rgb(149, 149, 139);
}

.framerTextBoxsm{
    box-shadow: 5px 5px rgb(149, 149, 139);
}

.main{
    scroll-snap-type: y mandatory;
}

.area{
    scroll-snap-align: start;
}

.arrowdown{
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 100px solid black;
}

.smallarrow{
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid rgb(30 41 59);
}

.tooltip .arrow{
    width: 20px;
    height: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid black;
}

.tooltip .text{
    display: flex;
    justify-content: center;
    align-items: center;
}